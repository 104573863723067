import * as React from 'react';

import Layout from 'components/core/layout';
import Seo from 'components/core/seo';
import A from 'components/core/a';
import P from 'components/core/p';
import Span from 'components/core/span';
import ListOfBookmarks from 'components/pages/bookmarks/list-section';

const BookmarksPage = () => (
  <Layout>
    <Seo title="Bookmarks" />
    <P>
      (last updated: 05/21) I am adding totally random things that I found interesting. Any recommendations?{' '}
      <Span>
        <A href="mailto: ctuncelli@yahoo.com" text="Let me know." />
      </Span>
    </P>
    <ListOfBookmarks />
  </Layout>
);

export default BookmarksPage;
