import * as React from 'react';

import A from '../../core/a';
import SectionContainer from '../../core/section-container';

const Others = () => {
  return (
    <>
      <SectionContainer>
        <ul className="text-sm">
          <li>
            <A
              href="https://medium.com/airbnb-engineering/how-airbnb-built-wall-to-prevent-data-bugs-ad1b081d6e8f"
              text="How Airbnb Built “Wall” to prevent data bugs"
            />
          </li>
          <li>
            <A href="https://www.cardesignhistory.com/" text="Car Design History" />
          </li>
          <li>
            <A href="https://12factor.net/" text="The Twelve-Factor App" />
          </li>
          <li>
            <A href="https://arxiv.org/pdf/2103.12732.pdf" text="DEX with AMM Protocols" />
          </li>
          <li>
            <A href="http://paulgraham.com/newideas.html" text="Crazy New Ideas (Paul Graham)" />
          </li>
          <li>
            <A
              href="https://medium.com/sequoia-capital/rippling-the-rebundling-of-enterprise-software-89b9250e8258"
              text="Rippling: The Rebundling of Enterprise Software"
            />
          </li>
          <li>
            <A
              href="https://www.pnas.org/content/118/41/e2021636118"
              text="Slowed canonical progress in large fields of science"
            />
          </li>
          <li>
            <A href="https://www.wired.com/2010/08/ff-webrip/" text="The Web Is Dead. Long Live the Internet" />
          </li>
          <li>
            <A
              href="https://www.amazon.com/Rise-Superman-Decoding-Ultimate-Performance-ebook/dp/B00BW54XVO"
              text="The Rise of Superman"
            />
          </li>
        </ul>
      </SectionContainer>
    </>
  );
};

export default Others;
